import React, { useState, useContext, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import API from "../API";
import useFHConnectUser from "./useFHConnectUser";
import { usePrivateLabel } from "./privateLabelContetx";

type AdminLogin = {
  token: string;
  exp: number;
  email: string;
  permissions: string[];
  role: string;
  userRole?: string;
  userRoleName?: string;
};

interface AuthenticationData {
  idToken: {
    jwtToken: string;
    payload: { email: string; exp: number };
  };
  refreshToken: {
    token: string;
  };
  accessToken: {
    jwtToken: string;
    payload: string;
  };
  clockDrift: number;
}

type SignInResponse = {
  otpUrlMFA?: string;
  sessionToken: string;
};

type VerifyCodeMFAProps = {
  code: string;
  sessionToken: string;
  email: string;
};

type AdminContextType = {
  admin: AdminLogin;
  loading: boolean;
  setAdmin: React.Dispatch<React.SetStateAction<AdminLogin>>;
  signIn: (
    email: string,
    password: string
  ) => Promise<SignInResponse | { messageError: string }>;
  logout: () => void;
  logoutUser: () => void;
  handleRefreshUser: () => void;
  signInWithToken: (admin: {
    token: { expires_in: string; id_token: string };
    permissions: string[];
    role?: string;
    email?: string;
  }) => Promise<void>;
  verifyCodeMFA: ({
    code,
    sessionToken,
    email,
  }: VerifyCodeMFAProps) => Promise<any>;
};

const MILLISECONDS_IN_SECOND = 1000;

export const AdminContext = React.createContext<AdminContextType>({
  admin: {
    token: "",
    exp: 0,
    email: "",
    permissions: [""],
    role: "",
  },
  loading: false,
  logout: () => {},
  logoutUser: () => {},
  signInWithToken: async () => {},
  handleRefreshUser: () => {},
  setAdmin: () => {},
  signIn: async () => {
    return {
      otpUrlMFA: undefined,
      sessionToken: "",
      messageError: undefined,
    };
  },
  verifyCodeMFA: async () => {},
});

const useAdmin = () => {
  return useContext(AdminContext);
};

export const AdminCustomerContextProvider = ({ children }) => {
  const navigate = useNavigate();

  const FHConnectUser = useFHConnectUser();

  const { handleRefreshPrivateLabel } = usePrivateLabel();
  const [admin, setAdmin] = useState<AdminLogin>({
    token: localStorage.getItem("NFTdoorAdminToken"),
    exp: Number(localStorage.getItem("NFTdoorAdminTokenExp")),
    email: localStorage.getItem("NFTdoorAdminTokenEmail"),
    permissions: JSON.parse(
      localStorage.getItem("NFTdoorAdminPermissions") || "[]"
    ),
    role: JSON.parse(localStorage.getItem("NFTdoorAdminRole")),
  });
  const [loading, setLoading] = useState(false);

  const currentEnv = localStorage.getItem("currentEnvironment");

  const logout = useCallback(() => {
    localStorage.clear();
    localStorage.setItem("currentEnvironment", currentEnv);
  }, []);

  const logoutUser = () => {
    localStorage.removeItem("NFTdoorAdminToken");
    localStorage.removeItem("NFTdoorAdminTokenExp");
    localStorage.removeItem("NFTdoorAdminPermissions");
    localStorage.removeItem("NFTdoorAdminRole");
  };

  const signIn = useCallback(
    async (
      email: string,
      password: string
    ): Promise<SignInResponse | { messageError: string }> => {
      setLoading(true);
      const result = await API.post({
        url: "/auth/login",
        data: {
          email,
          password,
        },
      });
      if ("error" in result) {
        setLoading(false);
        return { messageError: result?.errorId?.message };
      }

      if (result?.data?.skipMfa) {
        const authData = result?.data?.authData as AuthenticationData;

        const payload = authData?.idToken?.payload;

        if (payload) {
          FHConnectUser(
            payload["cognito:username"],
            payload.email,
            payload.email
          );
        }

        setAdmin({
          token: authData?.idToken?.jwtToken,
          exp: authData?.idToken?.payload.exp * MILLISECONDS_IN_SECOND,
          email: authData?.idToken?.payload?.email,
          permissions: result.data?.permissions,
          role: result?.data?.role,
        });

        const permissions = result?.data?.permissions || [];
        const specificPermission = "2efaf7e1-cc7d-4c1f-a729-c33bacea2c75";

        const hasViewBrokerApp = permissions.includes(specificPermission);

        const userRole = result?.data?.role;
        const defaultBrokerAppRoute =
          userRole === "Account Executive" ||
          userRole === "Broker" ||
          userRole === "Broker REMN" ||
          userRole === "Broker HomeBridge";

        localStorage.setItem(
          "NFTdoorAdminId",
          result.data?.authData?.accessToken?.payload?.sub
        );
        localStorage.setItem(
          "NFTdoorAdminToken",
          result.data?.authData?.idToken?.jwtToken
        );
        localStorage.setItem(
          "NFTdoorAdminTokenExp",
          (
            result.data?.authData?.idToken?.payload.exp * MILLISECONDS_IN_SECOND
          ).toString()
        );
        localStorage.setItem(
          "NFTdoorAdminTokenEmail",
          result.data?.authData?.idToken?.payload?.email
        );
        localStorage.setItem(
          "NFTdoorAdminPermissions",
          JSON.stringify(result.data?.permissions)
        );
        localStorage.setItem(
          "NFTdoorAdminRole",
          JSON.stringify(result?.data?.role)
        );

        navigate(
          defaultBrokerAppRoute
            ? hasViewBrokerApp
              ? "/broker-apps"
              : "/applications"
            : "/",
          { replace: true }
        );

        const isLogged = !!(
          localStorage.getItem("NFTdoorAdminTokenEmail") &&
          localStorage.getItem("NFTdoorAdminToken") &&
          localStorage.getItem("NFTdoorAdminTokenExp")
        );
        await handleRefreshPrivateLabel(isLogged);

        setLoading(false);
      } else {
        const authData: SignInResponse = result?.data?.authData;

        const sessionToken = authData?.sessionToken;

        if (sessionToken) {
          setLoading(false);
          return authData;
        }

        setLoading(false);
      }
    },
    []
  );

  const verifyCodeMFA = useCallback(
    async ({ code, sessionToken, email }: VerifyCodeMFAProps) => {
      setLoading(true);
      const result = await API.post({
        url: `/auth/verify-code-mfa`,
        method: "POST",
        data: {
          code,
          sessionToken,
          email,
        },
      });
      if ("error" in result) {
        setLoading(false);
        return result;
      }

      const verifyCodeRes = result?.data;

      if ("redirectToLogin" in verifyCodeRes) {
        window.location.reload();
        return null;
      }
      if ("authData" in verifyCodeRes) {
        const authData = verifyCodeRes.authData as AuthenticationData;

        const payload = authData?.idToken?.payload;

        if (payload) {
          FHConnectUser(
            payload["cognito:username"],
            payload.email,
            payload.email
          );
        }

        setAdmin({
          token: authData?.idToken?.jwtToken,
          exp: authData?.idToken?.payload.exp * MILLISECONDS_IN_SECOND,
          email: authData?.idToken?.payload?.email,
          permissions: result.data?.permissions,
          role: result?.data?.role,
        });

        const permissions = result?.data?.permissions || [];
        const specificPermission = "2efaf7e1-cc7d-4c1f-a729-c33bacea2c75";

        const hasViewBrokerApp = permissions.includes(specificPermission);

        const userRole = result?.data?.role;
        const defaultBrokerAppRoute =
          userRole === "Account Executive" ||
          userRole === "Broker" ||
          userRole === "Broker REMN" ||
          userRole === "Broker HomeBridge";

        localStorage.setItem(
          "NFTdoorAdminId",
          result.data?.authData?.accessToken?.payload?.sub
        );
        localStorage.setItem(
          "NFTdoorAdminToken",
          result.data?.authData?.idToken?.jwtToken
        );
        localStorage.setItem(
          "NFTdoorAdminTokenExp",
          (
            result.data?.authData?.idToken?.payload.exp * MILLISECONDS_IN_SECOND
          ).toString()
        );
        localStorage.setItem(
          "NFTdoorAdminTokenEmail",
          result.data?.authData?.idToken?.payload?.email
        );
        localStorage.setItem(
          "NFTdoorAdminPermissions",
          JSON.stringify(result.data?.permissions)
        );
        localStorage.setItem(
          "NFTdoorAdminRole",
          JSON.stringify(result?.data?.role)
        );

        navigate(
          defaultBrokerAppRoute
            ? hasViewBrokerApp
              ? "/broker-apps"
              : "/applications"
            : "/",
          { replace: true }
        );

        const isLogged = !!(
          localStorage.getItem("NFTdoorAdminTokenEmail") &&
          localStorage.getItem("NFTdoorAdminToken") &&
          localStorage.getItem("NFTdoorAdminTokenExp")
        );
        await handleRefreshPrivateLabel(isLogged);

        setLoading(false);
      }
    },
    []
  );

  const handleLoadUser = () => {
    if (admin.token && Number(admin.exp) > new Date().getTime()) {
      API.get("/get/get-admin-user/users").then((result) => {
        setAdmin((prev) => ({ ...prev, ...result.data }));
        if (result?.data) {
          FHConnectUser(
            result.data.id,
            `${result.data.firstName} ${result.data.lastName}`,
            result.data.email
          );
        }
      });
    }
  };

  const handleRefreshUser = () => {
    handleLoadUser();
  };

  useEffect(() => {
    handleLoadUser();
  }, [admin.token, admin.exp]);

  const signInWithToken = useCallback(
    async (admin: {
      token: { expires_in: string; id_token: string };
      permissions: string[];
      role?: string;
      email?: string;
    }) => {
      const expiration =
        new Date().getTime() +
        parseInt(admin.token.expires_in) * MILLISECONDS_IN_SECOND;
      setAdmin({
        token: admin?.token?.id_token,
        exp: expiration,
        permissions: admin?.permissions ?? [],
        role: admin?.role,
        email: admin?.email,
      });

      localStorage.setItem(
        "NFTdoorAdminPermissions",
        JSON.stringify(admin.permissions)
      );
      navigate("/loans");
      window.location.reload();
    },
    [admin]
  );

  const tokenExp = localStorage.getItem("NFTdoorAdminTokenExp");
  const currentTime = new Date().getTime();
  const timeUntilExpiration = Number(tokenExp) - currentTime;

  if (timeUntilExpiration >= 0) {
    setTimeout(() => {
      API.get("/get/get-admin-user/users").then((result) => {
        if (result?.data?.permissions) {
          localStorage.setItem(
            "NFTdoorAdminPermissions",
            JSON.stringify(result.data?.permissions)
          );
        }
        if (result?.data?.role) {
          localStorage.setItem(
            "NFTdoorAdminRole",
            JSON.stringify(result?.data?.role)
          );
        }
      });
    }, timeUntilExpiration);
  }

  return (
    <AdminContext.Provider
      value={{
        admin,
        loading,
        setAdmin,
        logout,
        signIn,
        logoutUser,
        signInWithToken,
        handleRefreshUser,
        verifyCodeMFA,
      }}
    >
      {children}
    </AdminContext.Provider>
  );
};

export default useAdmin;
