import UILoader from "@src/@core/components/ui-loader";
import API from "@src/utility/API";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import checkIpOrLocalhost from "../checkIpOrLocalhost";
import { PrivateLabel } from "@src/types/PrivateLabel";

interface PrivateLabelContextProps {
  privateLabel?: PrivateLabel;
  hostName?: string;
  handleRefreshPrivateLabel?: (value: boolean) => Promise<void>;
}

const PrivateLabelContext = createContext<PrivateLabelContextProps>(undefined);

const PrivateLabelContextProvider = ({ children }) => {
  const [privateLabel, setPrivateLabel] = useState<PrivateLabel>();
  const [isLoading, setIsLoading] = useState(true);
  const [hostName, setHostName] = useState("");

  const handleRefreshPrivateLabel = async (isLogged) => {
    try {
      const rawHostname = checkIpOrLocalhost(window.location.hostname)
        ? process.env.REACT_APP_PRIVATE_LABEL_DOMAIN
        : window.location.hostname;

      const hostname = rawHostname.replace("admin.", "");
      if (isLogged) {
        const original = `${hostname}`;
        const encoded = encodeURIComponent(original);
        await API.get(`/get/private-label/${encoded}`).then((result) => {
          setPrivateLabel(result.data);
        });
      } else {
        const response = await API.get(`/get-unsec/private-label/${hostname}`);

        if ("error" in response) {
          alert(response.error);
        } else {
          setPrivateLabel(response.data);
        }
      }
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  };

  const cleanAdminString = (str: string) => str.replace("admin.", "");
  const fetchData = async (hostname: string) => {
    try {
      const isLogged = !!(
        localStorage.getItem("NFTdoorAdminTokenEmail") &&
        localStorage.getItem("NFTdoorAdminToken") &&
        localStorage.getItem("NFTdoorAdminTokenExp")
      );
      if (isLogged) {
        const original = `${hostname}`;
        const encoded = encodeURIComponent(original);
        await API.get(`/get/private-label/${encoded}`).then((result) => {
          setPrivateLabel(result.data);
        });
      } else {
        const response = await API.get(`/get-unsec/private-label/${hostname}`);

        if ("error" in response) {
          alert(response.error);
        } else {
          setPrivateLabel(response.data);
        }
      }
    } catch (error) {
      console.error("Error:", error);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    //change tes.nftydoor with your privatelabel to test
    const rawHostname = checkIpOrLocalhost(window.location.hostname)
      ? process.env.REACT_APP_PRIVATE_LABEL_DOMAIN
      : window.location.hostname;

    const hostname = cleanAdminString(rawHostname);
    if (hostname) {
      setHostName(hostname);
      fetchData(hostname);
    }
  }, []);

  useEffect(() => {
    if (privateLabel?.mediaResources?.favicon) {
      const link = document.createElement("link");
      link.rel = "icon";
      link.href = privateLabel.mediaResources.favicon;
      document.getElementsByTagName("head")[0].appendChild(link);
    }

    if (privateLabel?.lenderName) {
      document.title = `${privateLabel?.lenderName} | Admin Dashboard`;
    }
  }, [privateLabel]);

  const values = useMemo(
    () => ({
      privateLabel: privateLabel,
      hostName: hostName,
      handleRefreshPrivateLabel,
    }),
    [privateLabel, hostName]
  );

  if (isLoading) return <UILoader />;

  return (
    <PrivateLabelContext.Provider value={values}>
      {children}
    </PrivateLabelContext.Provider>
  );
};

export function usePrivateLabel() {
  const context = useContext(PrivateLabelContext);
  if (!context) {
    throw new Error("PrivateLabelContextProvider not found");
  }
  return context;
}

export { PrivateLabelContextProvider, PrivateLabelContext };
